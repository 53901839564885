import React from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6Ldq28IpAAAAAOXvjuTV47z1NPjHOVgGng5h0Df9">
            {element}
        </GoogleReCaptchaProvider>
    )
};